<template>
  <div class="error-container d-flex align-center justify-center">
    <div style="width:100%;">
      <v-img :src="require('@/assets/images/error-404.svg')"
             :aspect-ratio="880/600"
             width="100%" max-width="880"
             :alt="message"
             contain />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404Page',
  data: () => ({
    message: 'Page not found'
  }),
}
</script>

<style lang="scss" scoped>
.error-container {
  height: 100%;
  padding: 10px;
}
</style>
